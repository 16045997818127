import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectList({ options, selected, onChange }) {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className=" ">
            <Listbox.Button className="relative w-[120px] bg-primary hover:cursor-pointer rounded-lg py-2 pl-3 pr-10 text-left  shadow-sm  focus:outline-none ">
              <span className="block truncate capitalize text-btnTextColor">
                {selected}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-6 w-6 text-btnTextColor"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-[9] max-h-60 w-[120px] mt-1 overflow-auto rounded-md bg-btnTextColor py-1 text-base shadow-lg ring-1 ring-textColor ring-opacity-5 focus:outline-none sm:text-sm">
                {options?.map((person) => (
                  <Listbox.Option
                    key={person?.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-[#EBF3FB] text-primary" : "text-gray-900 ",
                        "relative select-none py-2 pl-3 pr-9 hover:cursor-pointer"
                      )
                    }
                    value={person?.attributes?.sortBy}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected
                              ? "font-semibold capitalize"
                              : "font-normal capitalize",
                            "block truncate capitalize"
                          )}
                        >
                          {person?.attributes?.sortBy}
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-textColor" : "",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
